import React from "react";
import useStyles from "./styles";
import {
  Typography,
  Container,
  CssBaseline,
  Avatar,
  TextField,
  Button,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { green } from "@material-ui/core/colors";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useLoginMutation } from "../../generated/graphql";
import { useSnackbar } from "notistack";
import { useStoreActions, useStoreState } from "../../hooks/";
import { Redirect } from "react-router-dom";

const LoginSchema = yup.object().shape({
  tcno: yup
    .string()
    .trim()
    .min(10, "TCNO en az 10 haneli olmalı.")
    .required("TCNO giriniz."),
  password: yup.string().required("Şifre giriniz."),
});
export interface ILogin {
  tcno: string;
  password: string;
}

const Login: React.FC = () => {
  const classes = useStyles();
  const { register, handleSubmit, errors, reset } = useForm<ILogin>({
    validationSchema: LoginSchema,
  });
  const [loginMutation] = useLoginMutation();
  const { enqueueSnackbar } = useSnackbar();
  const addUser = useStoreActions((state) => state.user.addUser);
  const users = useStoreState((state) => state.user.users);
  const onSubmit = (formData: ILogin): void => {
    loginMutation({ variables: { tcno: formData.tcno } }).then((user) => {
      if (user.data?.login == null) {
        enqueueSnackbar("Hatalı TCNO!", { variant: "error" });
      } else {
        if (formData.password !== "bal1001") {
          enqueueSnackbar("Hatalı şifre!", { variant: "error" });
        } else {
          enqueueSnackbar("Giriş başarılı.", { variant: "success" });
          localStorage.setItem("token", user.data.login.token);
          const u = user.data.login.user;
          addUser({
            carikod: u.carkod,
            tcno: u.tcno,
            fullName: u.fullName,
            tel: u.tel,
            address: u.address,
            email: u.email,
          });
        }
      }
    });

    reset();
  };

  let authRedirect = null;
  if (users.length > 0) {
    if (users[0].tcno === "66663333000") {
      authRedirect = <Redirect to="/sales" />;
    } else {
      if (users[0].address == null) {
        enqueueSnackbar("İletişim bilgilerini giriniz.", {
          variant: "warning",
        });
        authRedirect = <Redirect to="/contact" />;
      } else authRedirect = <Redirect to="/order" />;
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {authRedirect}
        <Avatar
          className={classes.avatar}
          style={{ backgroundColor: green[500] }}
        >
          <LockOutlinedIcon />
        </Avatar>
        <Typography>Giriş</Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            inputRef={register}
            variant="outlined"
            margin="normal"
            type="number"
            required
            fullWidth
            id="tcno"
            label="TC NO veya Vergi NO"
            name="tcno"
            autoComplete="tcno"
            autoFocus
            error={!!errors.tcno}
            helperText={errors.tcno ? errors.tcno.message : ""}
          />
          <TextField
            inputRef={register}
            type="password"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            label="Şifre"
            name="password"
            error={!!errors.password}
            helperText={errors.password ? errors.password.message : ""}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Giriş
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default Login;
