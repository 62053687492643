import { action } from "easy-peasy";
import * as _ from "lodash";

import CartModel from "../interfaces/ProductModel";

const Cart: CartModel = {
  products: [],
  addProduct: action((state, product) => {
    state.products.push(product);
  }),
  removeProduct: action((state, product) => {
    _.remove(state.products, function (n) {
      return n.id === product.id;
    });
  }),
  clearAll: action((state) => {
    state.products = [];
  }),
  updateQuantity: action((state, product) => {
    let index = _.findIndex(state.products, function (o) {
      return o.id === product.id;
    });
    if (index !== -1) state.products[index].quantity = product.quantity;
  }),
};

export default Cart;
