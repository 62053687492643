import React from "react";
import { useCategoryQuery } from "../../generated/graphql";
import Category from "./category";

const CategoryContainer = () => {
  const { data, error, loading } = useCategoryQuery();

  if (loading) {
    return <p>Yükleniyor...</p>;
  }

  if (error || !data) {
    return <p>HATA!</p>;
  }

  return (
    <div>
      <Category data={data} />
    </div>
  );
};

export default CategoryContainer;
