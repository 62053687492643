import React from "react";
import { useCustomerQuery } from "../../../generated/graphql";
import List from "./list";

export interface Props {
  fullName: string;
}

const CategoryContainer: React.FC<Props> = ({ fullName }) => {
  const { data, error, loading } = useCustomerQuery({
    variables: { fullName: fullName },
  });

  if (loading) {
    return <p>Yükleniyor...</p>;
  }

  if (error || !data) {
    return <p>HATA!</p>;
  }

  return (
    <div>
      <List data={data} />
    </div>
  );
};

export default CategoryContainer;
