import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import useStyles from "./style";
import { Product } from "../../generated/graphql";
import { useStoreActions, useStoreState } from "../../hooks";
import * as _ from "lodash";

export interface Props {
  data: Array<Product> | null | undefined;
  category: string | null | undefined;
}

const Pro: React.FC<Props> = ({ data, category }) => {
  const classes = useStyles();
  const addProduct = useStoreActions((state) => state.product.addProduct);
  const removeProduct = useStoreActions((state) => state.product.removeProduct);
  const productStore = useStoreState((state) => state.product.products);

  const handleToggle = (value: Product) => () => {
    let isAdded = _.findIndex(productStore, function (o) {
      return o.id === value.id;
    });
    if (isAdded < 0) {
      addProduct({
        id: value.id!,
        name: value.name!,
        category: category!,
      });
    } else {
      removeProduct({
        id: value.id!,
        name: value.name!,
        category: category!,
      });
    }
    console.log("Added:" + isAdded);
  };

  return (
    <List dense className={classes.root}>
      {data?.map((value, index) => {
        return (
          <ListItem key={index} button>
            <ListItemText primary={value.name} />
            <ListItemSecondaryAction>
              <Checkbox edge="end" onChange={handleToggle(value)} />
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

export default Pro;
