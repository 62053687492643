import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type Query = {
  __typename?: 'Query';
  hello: Scalars['String'];
  category: Array<Category>;
  getUserFromName: Array<User>;
  getUserFromTCNO: User;
};


export type QueryGetUserFromNameArgs = {
  fullName: Scalars['String'];
};


export type QueryGetUserFromTcnoArgs = {
  tcno: Scalars['String'];
};

export type Category = {
  __typename?: 'Category';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  sira?: Maybe<Scalars['Int']>;
  product?: Maybe<Array<Product>>;
};

export type Product = {
  __typename?: 'Product';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['Int']>;
  sira?: Maybe<Scalars['Int']>;
  category?: Maybe<Category>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['Int'];
  fullName?: Maybe<Scalars['String']>;
  tcno?: Maybe<Scalars['String']>;
  vergino?: Maybe<Scalars['String']>;
  carkod?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  login?: Maybe<LoginResponse>;
  order: Scalars['Boolean'];
};


export type MutationLoginArgs = {
  tcno?: Maybe<Scalars['String']>;
};


export type MutationOrderArgs = {
  tcno?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  orders: Array<OrderProductInput>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  token: Scalars['String'];
  user: User;
};

export type OrderProductInput = {
  id: Scalars['Float'];
  quantity: Scalars['Float'];
};

export type Order = {
  __typename?: 'Order';
  id?: Maybe<Scalars['Int']>;
  product?: Maybe<Product>;
  user?: Maybe<User>;
  quantity?: Maybe<Scalars['Int']>;
  date_created: Scalars['DateTime'];
  orderNumber?: Maybe<Scalars['String']>;
};


export type CategoryQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoryQuery = (
  { __typename?: 'Query' }
  & { category: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name' | 'sira'>
    & { product?: Maybe<Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'unit' | 'sira'>
    )>> }
  )> }
);

export type GetContactsQueryVariables = Exact<{
  tcno: Scalars['String'];
}>;


export type GetContactsQuery = (
  { __typename?: 'Query' }
  & { getUserFromTCNO: (
    { __typename?: 'User' }
    & Pick<User, 'tel' | 'address' | 'email'>
  ) }
);

export type CustomerQueryVariables = Exact<{
  fullName: Scalars['String'];
}>;


export type CustomerQuery = (
  { __typename?: 'Query' }
  & { getUserFromName: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'tcno' | 'vergino' | 'fullName'>
  )> }
);

export type LoginMutationVariables = Exact<{
  tcno: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'token'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'fullName' | 'tcno' | 'carkod' | 'tel' | 'address' | 'email'>
    ) }
  )> }
);

export type OrderMutationVariables = Exact<{
  orders: Array<OrderProductInput>;
  description?: Maybe<Scalars['String']>;
  tcno?: Maybe<Scalars['String']>;
}>;


export type OrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'order'>
);


export const CategoryDocument = gql`
    query Category {
  category {
    id
    name
    sira
    product {
      id
      name
      unit
      sira
    }
  }
}
    `;
export type CategoryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CategoryQuery, CategoryQueryVariables>, 'query'>;

    export const CategoryComponent = (props: CategoryComponentProps) => (
      <ApolloReactComponents.Query<CategoryQuery, CategoryQueryVariables> query={CategoryDocument} {...props} />
    );
    
export type CategoryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CategoryQuery, CategoryQueryVariables>
    } & TChildProps;
export function withCategory<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CategoryQuery,
  CategoryQueryVariables,
  CategoryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CategoryQuery, CategoryQueryVariables, CategoryProps<TChildProps, TDataName>>(CategoryDocument, {
      alias: 'category',
      ...operationOptions
    });
};

/**
 * __useCategoryQuery__
 *
 * To run a query within a React component, call `useCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CategoryQuery, CategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, baseOptions);
      }
export function useCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CategoryQuery, CategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, baseOptions);
        }
export type CategoryQueryHookResult = ReturnType<typeof useCategoryQuery>;
export type CategoryLazyQueryHookResult = ReturnType<typeof useCategoryLazyQuery>;
export type CategoryQueryResult = ApolloReactCommon.QueryResult<CategoryQuery, CategoryQueryVariables>;
export const GetContactsDocument = gql`
    query getContacts($tcno: String!) {
  getUserFromTCNO(tcno: $tcno) {
    tel
    address
    email
  }
}
    `;
export type GetContactsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetContactsQuery, GetContactsQueryVariables>, 'query'> & ({ variables: GetContactsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetContactsComponent = (props: GetContactsComponentProps) => (
      <ApolloReactComponents.Query<GetContactsQuery, GetContactsQueryVariables> query={GetContactsDocument} {...props} />
    );
    
export type GetContactsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetContactsQuery, GetContactsQueryVariables>
    } & TChildProps;
export function withGetContacts<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetContactsQuery,
  GetContactsQueryVariables,
  GetContactsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetContactsQuery, GetContactsQueryVariables, GetContactsProps<TChildProps, TDataName>>(GetContactsDocument, {
      alias: 'getContacts',
      ...operationOptions
    });
};

/**
 * __useGetContactsQuery__
 *
 * To run a query within a React component, call `useGetContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsQuery({
 *   variables: {
 *      tcno: // value for 'tcno'
 *   },
 * });
 */
export function useGetContactsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContactsQuery, GetContactsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContactsQuery, GetContactsQueryVariables>(GetContactsDocument, baseOptions);
      }
export function useGetContactsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContactsQuery, GetContactsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContactsQuery, GetContactsQueryVariables>(GetContactsDocument, baseOptions);
        }
export type GetContactsQueryHookResult = ReturnType<typeof useGetContactsQuery>;
export type GetContactsLazyQueryHookResult = ReturnType<typeof useGetContactsLazyQuery>;
export type GetContactsQueryResult = ApolloReactCommon.QueryResult<GetContactsQuery, GetContactsQueryVariables>;
export const CustomerDocument = gql`
    query Customer($fullName: String!) {
  getUserFromName(fullName: $fullName) {
    id
    tcno
    vergino
    fullName
  }
}
    `;
export type CustomerComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CustomerQuery, CustomerQueryVariables>, 'query'> & ({ variables: CustomerQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CustomerComponent = (props: CustomerComponentProps) => (
      <ApolloReactComponents.Query<CustomerQuery, CustomerQueryVariables> query={CustomerDocument} {...props} />
    );
    
export type CustomerProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CustomerQuery, CustomerQueryVariables>
    } & TChildProps;
export function withCustomer<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CustomerQuery,
  CustomerQueryVariables,
  CustomerProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CustomerQuery, CustomerQueryVariables, CustomerProps<TChildProps, TDataName>>(CustomerDocument, {
      alias: 'customer',
      ...operationOptions
    });
};

/**
 * __useCustomerQuery__
 *
 * To run a query within a React component, call `useCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerQuery({
 *   variables: {
 *      fullName: // value for 'fullName'
 *   },
 * });
 */
export function useCustomerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
        return ApolloReactHooks.useQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, baseOptions);
      }
export function useCustomerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, baseOptions);
        }
export type CustomerQueryHookResult = ReturnType<typeof useCustomerQuery>;
export type CustomerLazyQueryHookResult = ReturnType<typeof useCustomerLazyQuery>;
export type CustomerQueryResult = ApolloReactCommon.QueryResult<CustomerQuery, CustomerQueryVariables>;
export const LoginDocument = gql`
    mutation Login($tcno: String!) {
  login(tcno: $tcno) {
    token
    user {
      id
      fullName
      tcno
      carkod
      tel
      address
      email
    }
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;
export type LoginComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LoginMutation, LoginMutationVariables>, 'mutation'>;

    export const LoginComponent = (props: LoginComponentProps) => (
      <ApolloReactComponents.Mutation<LoginMutation, LoginMutationVariables> mutation={LoginDocument} {...props} />
    );
    
export type LoginProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>
    } & TChildProps;
export function withLogin<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LoginMutation,
  LoginMutationVariables,
  LoginProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, LoginMutation, LoginMutationVariables, LoginProps<TChildProps, TDataName>>(LoginDocument, {
      alias: 'login',
      ...operationOptions
    });
};

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      tcno: // value for 'tcno'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const OrderDocument = gql`
    mutation Order($orders: [OrderProductInput!]!, $description: String, $tcno: String) {
  order(orders: $orders, description: $description, tcno: $tcno)
}
    `;
export type OrderMutationFn = ApolloReactCommon.MutationFunction<OrderMutation, OrderMutationVariables>;
export type OrderComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<OrderMutation, OrderMutationVariables>, 'mutation'>;

    export const OrderComponent = (props: OrderComponentProps) => (
      <ApolloReactComponents.Mutation<OrderMutation, OrderMutationVariables> mutation={OrderDocument} {...props} />
    );
    
export type OrderProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<OrderMutation, OrderMutationVariables>
    } & TChildProps;
export function withOrder<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrderMutation,
  OrderMutationVariables,
  OrderProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, OrderMutation, OrderMutationVariables, OrderProps<TChildProps, TDataName>>(OrderDocument, {
      alias: 'order',
      ...operationOptions
    });
};

/**
 * __useOrderMutation__
 *
 * To run a mutation, you first call `useOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderMutation, { data, loading, error }] = useOrderMutation({
 *   variables: {
 *      orders: // value for 'orders'
 *      description: // value for 'description'
 *      tcno: // value for 'tcno'
 *   },
 * });
 */
export function useOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OrderMutation, OrderMutationVariables>) {
        return ApolloReactHooks.useMutation<OrderMutation, OrderMutationVariables>(OrderDocument, baseOptions);
      }
export type OrderMutationHookResult = ReturnType<typeof useOrderMutation>;
export type OrderMutationResult = ApolloReactCommon.MutationResult<OrderMutation>;
export type OrderMutationOptions = ApolloReactCommon.BaseMutationOptions<OrderMutation, OrderMutationVariables>;