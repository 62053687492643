import React from "react";
import { CustomerQuery } from "../../../generated/graphql";
import useStyles from "./styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { useStoreActions } from "../../../hooks";

export interface Props {
  data: CustomerQuery;
}

const CustomerList: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  const updateTCNO = useStoreActions((state) => state.user.updateTCNO);
  const handleToggle = (tcno: string) => () => {
    updateTCNO(tcno);
  };
  return (
    <List dense className={classes.root}>
      {data.getUserFromName.map((val, index) => {
        return (
          <ListItem key={index} button>
            <ListItemText primary={val.fullName} />
            <ListItemSecondaryAction>
              <Checkbox
                edge="end"
                onChange={handleToggle(
                  val.tcno !== "NULL" ? val.tcno! : val.vergino!
                )}
              />
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

export default CustomerList;
