import React, { useEffect } from "react";
const Exit: React.FC = () => {
  useEffect(() => {
    localStorage.removeItem("token");
    window.location.href = "/";
  }, []);

  return <div>Çıkış Yapılıyor...</div>;
};

export default Exit;
