import React from "react";
import TextField from "@material-ui/core/TextField";
import { useStoreActions, useStoreState } from "../../hooks";

const Quantity: React.FC = () => {
  const products = useStoreState((state) => state.product.products);
  const updateQuantity = useStoreActions(
    (state) => state.product.updateQuantity
  );

  return (
    <div>
      {products.map((product, index) => (
        <span key={index}>
          <TextField
            key={index}
            label={product.name + " - " + product.category}
            type="number"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            style={{ marginBottom: "5%" }}
            onChange={(event) => {
              product.quantity = parseInt(event.target.value);
              updateQuantity(product);
            }}
          />
        </span>
      ))}
    </div>
  );
};

export default Quantity;
