import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import { useStoreState } from "../../hooks";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    width: "100%",
    maxWidth: 500,
  },
});

export default function TypographyTheme() {
  const classes = useStyles();
  const products = useStoreState((state) => state.product.products);
  return (
    <div className={classes.root}>
      {products.map((product, index) => (
        <span key={index}>
          <Typography variant="subtitle1">
            {product.category + " - " + product.name}
            <Divider component="br" />
            {product.quantity + " Adet"}
          </Typography>
          <Divider />
        </span>
      ))}
    </div>
  );
}
