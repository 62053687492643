import React, { useEffect } from "react";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useStyles from "./style";
import { CategoryQuery } from "../../generated/graphql";
import Product from "../Product";
import { useStoreActions } from "../../hooks";

export interface Props {
  data: CategoryQuery;
}

const Category: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  const clearAll = useStoreActions((state) => state.product.clearAll);
  useEffect(() => {
    clearAll();
  }, []);
  return (
    <div className={classes.root}>
      {data.category.map((val, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>{val.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Product data={val.product} category={val.name} />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default Category;
