import React from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";

import Login from "./components/Login";
import Order from "./components/Order";
import Exit from "./components/Exit";
import Sales from "./components/Sales";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/order" component={Order} />
          <Route path="/sales" component={Sales} />
          <Route path="/exit" component={Exit} />
          <Route path="/" component={Login} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
