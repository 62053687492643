import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";
import { useStoreState } from "../../hooks/";
import { Redirect } from "react-router-dom";
import Category from "../Category";
import { useSnackbar } from "notistack";
import Quantity from "../Quantity";
import Summary from "../Summary";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useOrderMutation } from "../../generated/graphql";
import Description from "../Description";
import CustomerList from "../Customer";

function getSteps() {
  return ["Müşteri Seçimi", "Ürün Seçimi", "Miktar Girme", "Özet", "Açıklama"];
}

function getStepContent(step: number) {
  switch (step) {
    case 0:
      return <CustomerList />;
    case 1:
      return <Category />;
    case 2:
      return <Quantity />;
    case 3:
      return <Summary />;
    case 4:
      return <Description />;
    default:
      return "Unknown step";
  }
}

export default function VerticalLinearStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [finish, setFinish] = React.useState(<div></div>);
  const [orderMutation] = useOrderMutation();
  const steps = getSteps();
  const users = useStoreState((state) => state.user.users);
  const products = useStoreState((state) => state.product.products);
  const { enqueueSnackbar } = useSnackbar();

  if (activeStep === 5) {
    // Sipariş kaydet
    let orderArr: Array<{ id: number; quantity: number }> = [];
    products.forEach((product) => {
      orderArr.push({ id: product.id, quantity: product.quantity! });
    });

    orderMutation({
      variables: {
        orders: orderArr,
        description: users[0].description,
        tcno: users[0].tcno,
      },
    }).then((result) => {
      enqueueSnackbar("Sipariş gönderildi.", { variant: "success" });
      setFinish(<Redirect to="/exit" />);
    });
    setActiveStep(6);
  }

  const handleNext = () => {
    if (products.length < 1 && activeStep === 1)
      enqueueSnackbar("Sipariş için ürün seçmediniz.", { variant: "warning" });
    else if (activeStep === 0 && users[0].tcno === "66663333000") {
      enqueueSnackbar("Müşteri seçmediniz.", { variant: "warning" });
    } else if (
      activeStep === 0 &&
      (users[0].tcno === "NULL" || users[0].tcno === " ")
    ) {
      enqueueSnackbar("Müşteri kimlik bilgileri sistemde yok.", {
        variant: "warning",
      });
    } else setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  let authRedirect = null;
  if (users.length < 1) {
    authRedirect = <Redirect to="/" />;
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography component="span">{getStepContent(index)}</Typography>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Geri
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? "Tamamla" : "İleri"}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === 6 && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>Sipariş Gönderiliyor</Typography>
          <CircularProgress />
        </Paper>
      )}
      {authRedirect}
      {finish}
    </div>
  );
}
