import { action } from "easy-peasy";
import * as _ from "lodash";

import UserModel from "../interfaces/UserModel";

const User: UserModel = {
  users: [],
  addUser: action((state, user) => {
    state.users.push(user);
  }),
  updateContacts: action((state, user) => {
    let index = _.findIndex(state.users, function (o) {
      return o.tcno === user.tcno;
    });
    if (index !== -1) {
      if (user.address !== "") state.users[index].address = user.address;
      if (user.tel !== "") state.users[index].tel = user.tel;
      if (user.email !== "") state.users[index].email = user.email;
    }
  }),
  updateDescription: action((state, user) => {
    let index = _.findIndex(state.users, function (o) {
      return o.tcno === user.tcno;
    });
    if (index !== -1) {
      state.users[index].description = user.description;
    }
  }),
  updateTCNO: action((state, tcno) => {
    state.users[0].tcno = tcno;
  }),
};

export default User;
