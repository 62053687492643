import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import useStyles from "./styles";
import { Container, CssBaseline } from "@material-ui/core";
import CustomerList from "./List";

const Customer: React.FC = () => {
  const classes = useStyles();
  const [name, setName] = useState("");
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <form className={classes.form} noValidate>
          <TextField
            id="customer"
            name="customer"
            label="Müşteri"
            variant="outlined"
            fullWidth
            autoFocus
            margin="normal"
            required
            onChange={(event) => {
              setName(event.target.value);
            }}
          />

          <CustomerList fullName={name} />
        </form>
      </div>
    </Container>
  );
};

export default Customer;
