import { createStore } from "easy-peasy";

import Store from "../interfaces/Store";
import User from "./User";
import Product from "./Product";

const store: Store = {
  user: User,
  product: Product,
};

export default createStore<Store>(store);
