import React from "react";
import { TextField } from "@material-ui/core";
import { useStoreActions, useStoreState } from "../../hooks";

const Description: React.FC = () => {
  const users = useStoreState((state) => state.user.users);
  const updateDescription = useStoreActions(
    (state) => state.user.updateDescription
  );
  return (
    <div>
      <TextField
        id="description"
        name="description"
        label="Not Ekle"
        multiline
        rows={4}
        variant="outlined"
        fullWidth
        autoFocus
        margin="normal"
        onChange={(event) => {
          users[0].description = event.target.value;
          updateDescription(users[0]);
        }}
      />
    </div>
  );
};

export default Description;
